import { defineStore, acceptHMRUpdate } from 'pinia';
import { NotifyType } from '@jgo-idea/types';
import {
  FirebaseMessaging, Importance, Visibility, Notification,
} from '@capacitor-firebase/messaging';
import {
  LocalNotifications,
} from '@capacitor/local-notifications';
import { multiply, pipe } from 'remeda';
import { Notify, isNotifyData } from '@jgo-idea/common';
import { instance } from '../common/api';
import { until } from '@vueuse/core';
import { RouteLocationRaw, useRouter } from 'vue-router';
import { ROUTE_NAME } from '../router/router';

import { useUserStore } from './user.store';
import { useQuasar } from 'quasar';
import { useNotificationApi } from '@jgo-idea/api';

enum ChannelId {
  DEFAULT = 'default-channel',
}

export type SubscribeParam = {
  /** 可訂閱指定資源 id */
  id: string;
} | {
  type: `${NotifyType}`;
} | 'test';

/** 發送本地通知 */
function sendLocalNotify(notification: Notification, userId: string) {

  const notify: ReturnType<LocalNotifyProvider> = pipe(
    notification.data,
    (data) => {
      if (
        data && typeof data === 'object' &&
        'title' in data &&
        'body' in data
      ) {
        return {
          title: `${data.title}`,
          body: `${data.body}`,
        };
      }

      if (!isNotifyData(data)) {
        console.error('🚀 getNotificationMessage: not a notification data');
        return;
      }

      for (const provider of localNotifyProviders) {
        const notify = provider(data, userId);
        if (notify === null) return;
        if (notify === undefined) continue;
        return notify;
      }
    },
  )

  if (!notify?.title || !notify.body) return;

  LocalNotifications.schedule({
    notifications: [
      {
        channelId: ChannelId.DEFAULT,
        ...notify,
        schedule: {
          allowWhileIdle: true,
        },
        id: pipe(
          Math.random(),
          multiply(1000000),
          Math.floor
        ),
      },
    ],
  });
}

/** 回傳 undefined 表示不匹配請繼續，null 表示提早結束 */
type LocalNotifyProvider = (data: Notify['data'], userId: string) => {
  title: string;
  body: string;
  /** LocalNotificationSchema 可以在 extra 欄外儲存額外資料 */
  extra?: {
    navigation?: RouteLocationRaw;
  }
} | undefined | null

const localNotifyProviders: LocalNotifyProvider[] = [
  /** group-buy */
  (data, userId) => {
    if (data.domain !== 'group-buy') return;
    if (data.ownerId !== userId) return null;

    const domain = '[團購功能]';
    switch (data.event) {
      case 'create-order': {
        return {
          title: `${domain} 訂單成立`,
          body: `您好！您的商品「${data.name}」已成功售出，訂單編號為「${data.orderNumber}」。`,
          extra: {
            navigation: {
              name: ROUTE_NAME.ACCOUNT_GROUP_BUY_CREATOR_ORDER_DETAIL,
              params: { id: data.orderId },
            }
          },
        }
      }
    }
  },
  /** group-buy-order */
  (data, userId) => {
    if (data.domain !== 'group-buy-order') return;

    const domain = '[團購功能]';
    switch (data.event) {
      case 'not-paid-yet': {
        if (data.ownerId !== userId) return null;
        return {
          title: `${domain} 訂單尚未付款`,
          body: `提醒您，您的訂單編號「${data.orderNumber}」尚未付款，並且即將到期。請盡快進行匯款，以免訂單被取消。`,
          extra: {
            navigation: {
              name: ROUTE_NAME.ACCOUNT_GROUP_BUY_ORDER_DETAIL,
              params: { id: data.id },
            }
          },
        }
      }
      case 'payment-completed': {
        if (data.ownerId === userId) return null;
        return {
          title: `${domain} 付款完成`,
          body: `您好！訂單編號「${data.orderNumber}」的買家已完成匯款，尾碼為「${data.paymentInfo}」。確認後請變更訂單狀態！`,
          extra: {
            navigation: {
              name: ROUTE_NAME.ACCOUNT_GROUP_BUY_CREATOR_ORDER_DETAIL,
              params: { id: data.id },
            }
          },
        }
      }
      case 'status-update': {
        switch (data.status) {
          case 'shipped': {
            if (data.ownerId !== userId) return null;
            return {
              title: `${domain} 訂單已出貨`,
              body: `您的訂單編號「${data.orderNumber}」已經完成出貨！`,
              extra: {
                navigation: {
                  name: ROUTE_NAME.ACCOUNT_GROUP_BUY_ORDER_DETAIL,
                  params: { id: data.id },
                }
              },
            }
          }
          case 'cancelled': {
            const name = data.ownerId === userId
              ? ROUTE_NAME.ACCOUNT_GROUP_BUY_ORDER_DETAIL
              : ROUTE_NAME.ACCOUNT_GROUP_BUY_CREATOR_ORDER_DETAIL;

            return {
              title: `${domain} 訂單取消`,
              body: `抱歉通知您，您的訂單編號「${data.orderNumber}」已被取消。`,
              extra: {
                navigation: {
                  name,
                  params: { id: data.id },
                }
              },
            }
          }
        }
      }
    }
  },

  /** course */
  (data, userId) => {
    if (data.domain !== 'course') return;
    if (data.ownerId !== userId) return null;

    const domain = '[課程功能]';
    switch (data.event) {
      case 'create-order': {
        return {
          title: `${domain} 訂單成立`,
          body: `您好！您的課程「${data.name}」已成功售出，訂單編號為「${data.orderNumber}」。`,
          extra: {
            navigation: {
              name: ROUTE_NAME.ACCOUNT_COURSE_CREATOR_ORDER_DETAIL,
              params: { id: data.orderId },
            }
          },
        }
      }
    }
  },
  /** course-order */
  (data, userId) => {
    if (data.domain !== 'course-order') return;

    const domain = '[課程功能]';
    switch (data.event) {
      case 'not-paid-yet': {
        if (data.ownerId !== userId) return null;
        return {
          title: `${domain} 訂單尚未付款`,
          body: `提醒您，您的訂單編號「${data.orderNumber}」尚未付款，並且即將到期。請盡快進行匯款，以免訂單被取消。`,
          extra: {
            navigation: {
              name: ROUTE_NAME.ACCOUNT_COURSE_ORDER_DETAIL,
              params: { id: data.id },
            }
          },
        }
      }
      case 'payment-completed': {
        if (data.ownerId === userId) return null;
        return {
          title: `${domain} 付款完成`,
          body: `您好！訂單編號「${data.orderNumber}」的買家已完成匯款，尾碼為「${data.paymentInfo}」。確認後請變更訂單狀態！`,
          extra: {
            navigation: {
              name: ROUTE_NAME.ACCOUNT_COURSE_CREATOR_ORDER_DETAIL,
              params: { id: data.id },
            }
          },
        }
      }
      case 'status-update': {
        switch (data.status) {
          case 'shipped': {
            if (data.ownerId !== userId) return null;
            return {
              title: `${domain} 訂單已出貨`,
              body: `您的訂單編號「${data.orderNumber}」已經完成出貨！`,
              extra: {
                navigation: {
                  name: ROUTE_NAME.ACCOUNT_COURSE_ORDER_DETAIL,
                  params: { id: data.id },
                }
              },
            }
          }
          case 'cancelled': {
            const name = data.ownerId === userId
              ? ROUTE_NAME.ACCOUNT_COURSE_ORDER_DETAIL
              : ROUTE_NAME.ACCOUNT_COURSE_CREATOR_ORDER_DETAIL;

            return {
              title: `${domain} 訂單取消`,
              body: `抱歉通知您，您的訂單編號「${data.orderNumber}」已被取消。`,
              extra: {
                navigation: {
                  name,
                  params: { id: data.id },
                }
              },
            }
          }
        }
      }
    }
  },

  /** flea-market */
  (data, userId) => {
    if (data.domain !== 'flea-market') return;
    if (data.ownerId !== userId) return;

    const domain = '[二手功能]';
    switch (data.event) {
      case 'create-order': {
        return {
          title: `${domain} 訂單成立`,
          body: `您好！您的商品「${data.name}」已成功售出，訂單編號為「${data.orderNumber}」。`,
          extra: {
            navigation: {
              name: ROUTE_NAME.ACCOUNT_FLEA_MARKET_CREATOR_ORDER_DETAIL,
              params: { id: data.orderId },
            }
          },
        }
      }
    }
  },
  /** flea-market-order */
  (data, userId) => {
    if (data.domain !== 'flea-market-order') return;

    const domain = '[二手功能]';
    switch (data.event) {
      case 'not-paid-yet': {
        if (data.ownerId !== userId) return null;
        return {
          title: `${domain} 訂單尚未付款`,
          body: `提醒您，您的訂單編號「${data.orderNumber}」尚未付款，並且即將到期。請盡快進行匯款，以免訂單被取消。`,
          extra: {
            navigation: {
              name: ROUTE_NAME.ACCOUNT_FLEA_MARKET_ORDER_DETAIL,
              params: { id: data.id },
            }
          },
        }
      }
      case 'payment-completed': {
        if (data.ownerId === userId) return null;
        return {
          title: `${domain} 付款完成`,
          body: `您好！訂單編號「${data.orderNumber}」的買家已完成匯款，尾碼為「${data.paymentInfo}」。確認後請變更訂單狀態！`,
          extra: {
            navigation: {
              name: ROUTE_NAME.ACCOUNT_FLEA_MARKET_CREATOR_ORDER_DETAIL,
              params: { id: data.id },
            }
          },
        }
      }
      case 'status-update': {
        switch (data.status) {
          case 'shipped': {
            if (data.ownerId !== userId) return null;
            return {
              title: `${domain} 訂單已出貨`,
              body: `您的訂單編號「${data.orderNumber}」已經完成出貨！`,
              extra: {
                navigation: {
                  name: ROUTE_NAME.ACCOUNT_FLEA_MARKET_ORDER_DETAIL,
                  params: { id: data.id },
                }
              },
            }
          }
          case 'cancelled': {
            const name = data.ownerId === userId
              ? ROUTE_NAME.ACCOUNT_FLEA_MARKET_ORDER_DETAIL
              : ROUTE_NAME.ACCOUNT_FLEA_MARKET_CREATOR_ORDER_DETAIL;

            return {
              title: `${domain} 訂單取消`,
              body: `抱歉通知您，您的訂單編號「${data.orderNumber}」已被取消。`,
              extra: {
                navigation: {
                  name,
                  params: { id: data.id },
                }
              },
            }
          }
        }
      }
    }
  },

  /** career-standard */
  (data, userId) => {
    if (data.domain !== 'career-standard') return;

    const managers = data.managersCsv.split(',');
    if (!managers.includes(userId)) return null;

    const domain = '[應徵功能]';
    switch (data.event) {
      case 'create-application': {
        return {
          title: `${domain} 有人投遞履歷`,
          body: `您好！有新的應徵者於「${data.companyName}」的「${data.name}」項目投遞履歷。`,
          extra: {
            navigation: {
              name: ROUTE_NAME.CAREER_APPLICATION_MANAGER,
              query: {
                tab: 'career-standard',
                application: data.applicationId,
              },
            },
          },
        }
      }
    }
  },
  /** career-standard-application */
  (data, userId) => {
    if (data.domain !== 'career-standard-application') return;

    if (data.event !== 'status-update') return null;
    if (data.ownerId !== userId) return null;

    const extra = {
      navigation: {
        name: ROUTE_NAME.CAREER_APPLICATION,
        query: {
          tab: 'career-standard',
          application: data.id,
        }
      },
    }

    const domain = '[應徵功能]';
    switch (data.status) {
      case 'declined': {
        return {
          title: `${domain} 應徵被婉拒`,
          body: `「${data.companyName}」已婉拒您在「${data.name}」項目的應徵。`,
          extra,
        }
      }
      case 'to-be-contacted': {
        return {
          title: `${domain} 等待聯絡`,
          body: `提醒您！「${data.name}」項目確定通知聯絡，請等候「${data.companyName}」聯絡。`,
          extra,
        }
      }
      case 'admitted': {
        return {
          title: `${domain} 錄用通知`,
          body: `確定錄用通知，「${data.companyName}」的「${data.name}」項目已確定錄用，詳情請確認店家給應徵者的備註內容。`,
          extra,
        }
      }
      case 'not-hired': {
        return {
          title: `${domain} 不錄用通知`,
          body: `不錄用通知，「${data.companyName}」的「${data.name}」項目不錄用，詳情請確認店家給應徵者的備註內容。`,
          extra,
        }
      }
    }
  },

  /** career-model */
  (data, userId) => {
    if (data.domain !== 'career-model') return;

    const managers = data.managersCsv.split(',');
    if (!managers.includes(userId)) return null;

    const domain = '[模特功能]';
    switch (data.event) {
      case 'create-application': {
        return {
          title: `${domain} 有人投遞履歷`,
          body: `您好！有新的應徵者於「${data.companyName}」的「${data.name}」項目投遞履歷。`,
          extra: {
            navigation: {
              name: ROUTE_NAME.CAREER_APPLICATION_MANAGER,
              query: {
                tab: 'career-model',
                application: data.applicationId,
              },
            },
          },
        }
      }
    }
  },
  /** career-model-application */
  (data, userId) => {
    if (data.domain !== 'career-model-application') return;

    if (data.event !== 'status-update') return null;
    if (data.ownerId !== userId) return null;

    const extra = {
      navigation: {
        name: ROUTE_NAME.CAREER_APPLICATION,
        query: {
          tab: 'career-model',
          application: data.id,
        }
      },
    }

    const domain = '[模特功能]';
    switch (data.status) {
      case 'declined': {
        return {
          title: `${domain} 應徵被婉拒`,
          body: `「${data.companyName}」已婉拒您在「${data.name}」項目的應徵。`,
          extra,
        }
      }
      case 'to-be-contacted': {
        return {
          title: `${domain} 等待聯絡`,
          body: `提醒您！「${data.name}」項目確定通知聯絡，請等候「${data.companyName}」聯絡。`,
          extra,
        }
      }
      case 'admitted': {
        return {
          title: `${domain} 錄用通知`,
          body: `確定錄用通知，「${data.companyName}」的「${data.name}」項目已確定錄用，詳情請確認店家給應徵者的備註內容。`,
          extra,
        }
      }
      case 'not-hired': {
        return {
          title: `${domain} 不錄用通知`,
          body: `不錄用通知，「${data.companyName}」的「${data.name}」項目不錄用，詳情請確認店家給應徵者的備註內容。`,
          extra,
        }
      }
    }
  },
]

export const useNotificationStore = defineStore('notification', () => {
  const $q = useQuasar();
  const userStore = useUserStore();
  const router = useRouter();
  const notificationApi = useNotificationApi(instance);

  /** 訂閱指定主題 */
  async function subscribe(param: SubscribeParam) {
    if (!$q.platform.is.nativeMobile) return;

    const topic = pipe('',
      () => {
        if (typeof param === 'string') {
          return param;
        }

        if ('id' in param) {
          return param.id;
        }

        return param.type;
      },
    );

    return FirebaseMessaging.subscribeToTopic({ topic });
  }

  /** 自動訂閱主題，向 server 提出訂閱請求 */
  async function autoSubscribe() {
    if (!$q.platform.is.nativeMobile) return;

    const result = await FirebaseMessaging.getToken();
    return notificationApi.subscription(result);
  }

  /** 自動訂閱新訂單 */
  async function processSubscribeOrder(data: any) {
    if (!isNotifyData(data)) return;

    if (data.event === 'create-order') {
      return subscribe({ id: data.orderId });
    }
    if (data.event === 'create-application') {
      return subscribe({ id: data.applicationId });
    }
  }

  async function register() {
    if (!$q.platform.is.nativeMobile) return;

    let token: string | undefined = undefined;

    await Promise.all([
      FirebaseMessaging.requestPermissions(),
      LocalNotifications.requestPermissions(),
    ]);

    try {
      const result = await FirebaseMessaging.getToken();
      token = result.token;

      console.log(`🚀 FirebaseMessaging token: ${result.token}`);
    } catch (error) {
      console.error(`FirebaseMessaging getToken error: `, error);
    }

    try {
      await LocalNotifications.createChannel({
        id: ChannelId.DEFAULT,
        name: 'Default',
        description: 'default channel',
        importance: Importance.High,
        visibility: Visibility.Public,
        vibration: true,
        lights: true,
      });
    } catch (error) {
      console.error(`LocalNotifications createChannel error: `, error);
    }

    await FirebaseMessaging.addListener('notificationReceived', ({ notification }) => {
      console.log('🚀 Received a notification:', JSON.stringify(notification, null, 2));

      const userId = userStore.userInfo?._id;
      if (!userId) {
        console.warn('🚀 FirebaseMessaging addListener: user not login');
        return;
      }

      processSubscribeOrder(notification.data as any);

      sendLocalNotify(notification, userId);
    })

    LocalNotifications.addListener('localNotificationActionPerformed', (data) => {

      // 如果 navigation 存在，表示導航到指定位置
      if ('navigation' in data.notification.extra) {
        router.push(data.notification.extra.navigation);
      }
    });

    return token;
  }

  async function init() {
    /** 只在測試環境訂閱 test */
    if ($q.platform.is.nativeMobile) {
      const topic = 'test'
      if (['staging', 'development'].includes(import.meta.env.MODE)) {
        FirebaseMessaging.subscribeToTopic({ topic });
      } else {
        FirebaseMessaging.unsubscribeFromTopic({ topic });
      }
    }

    await until(() => userStore.isUserInfoLoading).toBe(false);

    if (!userStore.userInfo) {
      return;
    }

    await register();

    /** 訂閱自定義通知 */
    await subscribe({ type: 'info' })
    await autoSubscribe();
  }
  init();

  return {
    init,
    register,
    autoSubscribe,
    subscribe,
    async stop() {
      if (!$q.platform.is.nativeMobile) return;

      await FirebaseMessaging.removeAllListeners();

      /** FIX: 這裡有問題，會造成第一次啟動 App 登入時無法接收通知，
       * 必須重新登入才能接收通知
       */
      // return FirebaseMessaging.deleteToken();
    },
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useNotificationStore, import.meta.hot))
}


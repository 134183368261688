import { useRequest } from './utils';
export function useNotificationApi(instance) {
    const request = useRequest(instance);
    async function create(param) {
        return request({
            method: 'post',
            url: `/api/v1/notification`,
            data: param
        });
    }
    async function find(param) {
        return request({
            method: 'get',
            url: `/api/v1/notifications`,
            data: param
        });
    }
    async function subscription(param) {
        return request({
            method: 'post',
            url: `/api/v1/notification/subscription`,
            data: param
        });
    }
    return {
        create,
        find,
        subscription,
    };
}

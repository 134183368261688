<template>
  <q-layout
    v-touch-swipe.mouse.horizontal="handleSwipe"
    view="hHh lpR fFf"
    :style="layoutStyle"
  >
    <q-footer :model-value="navTabsVisible">
      <the-nav-tabs />
    </q-footer>

    <q-page-container
      ref="pageContainerRef"
      class="flex-col h-full md:items-center overflow-auto"
      :class="{ 'duration-[10s]': isViewBeforeUnmount }"
    >
      <router-view v-slot="{ Component }">
        <page-transition-wrapper>
          <div
            :key="(Component?.type as any)?.__name"
            class="flex-1 !duration-150"
          >
            <keep-alive :max="10">
              <component
                :is="Component"
                class="md:w-[500px]"
                @vue:before-unmount="isViewBeforeUnmount = true"
                @vue:before-mount="isViewBeforeUnmount = false"
              />
            </keep-alive>
          </div>
        </page-transition-wrapper>
      </router-view>
    </q-page-container>

    <!-- <q-dialog
      position="bottom"
      full-width
      no-refocus
      no-focus
      :model-value="groupBuyShoppingCartStore.visible"
      @update:model-value="groupBuyShoppingCartStore.setVisible"
    >
      <group-buy-shopping-cart
        class="!rounded-t-lg !max-h-none"
        @close="groupBuyShoppingCartStore.setVisible(false)"
      />
    </q-dialog> -->

    <group-buy-shopping-cart-dialog />
    <flea-market-shopping-cart-dialog />
    <app-update-dialog />
  </q-layout>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue';
import { Keyboard } from '@capacitor/keyboard';
import {
  AppTrackingTransparency,
} from 'capacitor-plugin-app-tracking-transparency';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { getDomain } from './common/utils';
import { storeToRefs } from 'pinia';

import TheNavTabs from './components/the-nav-tabs.vue';
import PageTransitionWrapper from './components/page-transition-wrapper.vue';
import GroupBuyShoppingCartDialog from './components/group-buy-shopping-cart-dialog.vue';
import FleaMarketShoppingCartDialog from './components/flea-market-shopping-cart-dialog.vue';
import AppUpdateDialog from './components/app-update-dialog.vue';

import {
  QPageContainer, TouchSwipe,
  TouchSwipeValue,
  useQuasar,
} from 'quasar';
import {
  useScreenOrientation,
  useActiveElement,
  promiseTimeout,
} from '@vueuse/core';
import { useRoute, useRouter } from 'vue-router';
import { useGroupBuySettingStore } from './stores/group-buy-setting.store';
import { useMainStore } from './stores/main.store';
import { useCourseSettingStore } from './stores/course-setting.store';
import { useNotificationStore } from './stores/notification.store';
import { useUserStore } from './stores/user.store';

// import VConsole from 'vconsole';

const route = useRoute();
const router = useRouter();

const mainStore = useMainStore();
const { version } = storeToRefs(mainStore);

watchEffect(() => {
  document.title = `找靈感美甲手創 v${version.value}`;
});

const $q = useQuasar();
/** 自動映射 icon，加上 sym_r 前綴
 * 
 * https://quasar.dev/vue-components/icon#custom-mapping
 */
$q.iconMapFn = (iconName) => ({
  icon: iconName.includes('sym_r') ? iconName : `sym_r_${iconName}`,
})

// 手機版開啟 vconsole
// if ($q.platform.is.mobile) {
//   new VConsole();
// }

useCourseSettingStore();
useGroupBuySettingStore();

useUserStore();
useNotificationStore();

const navTabsVisible = computed(() => {
  if (route.meta.hideNavTabs) {
    return false;
  }

  return mainStore.footerVisible;
});

App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
  const domain = getDomain();

  // Example url: https://beerswift.app/tabs/tabs2
  // slug = /tabs/tabs2
  const slug = event.url.replace(domain, '');
  mainStore.setDeepLinkUrl(slug);

  if (slug) {
    router.push({ path: slug });
  }
});

// 鎖定螢幕方向
const { isSupported, lockOrientation } = useScreenOrientation();
if (isSupported.value) {
  try {
    lockOrientation('portrait');
  } catch (error) {
    console.warn('鎖定螢幕發生錯誤 : ', error);
  }
} else {
  console.warn('不支援鎖定螢幕方向');
}

/** 處理手勢控制
 * 
 * TODO: 暫時停用手勢
 */
const handleSwipe: TouchSwipeValue = ({ evt, direction, duration }) => {
  // if (import.meta.env.PROD) {
  //   if (!$q.platform.is.nativeMobile) return;
  // }

  // if (
  //   direction !== 'right' ||
  //   !(evt instanceof TouchEvent)
  // ) return;

  // const touch = evt.touches[0];
  // if ((touch?.clientX ?? 0) > 60) return;

  // if (history.length > 1) {
  //   router.back();
  // }
}

const pageContainerRef = ref<InstanceType<typeof QPageContainer>>();
// useMutationObserver(pageContainerRef, () => {
//   const el = pageContainerRef.value?.$el;
//   if (!(el instanceof HTMLDivElement)) return;

//   const headerHeight = el.style.paddingTop || '0px';
//   const footerHeight = el.style.paddingBottom || '0px';
// }, {
//   attributes: true,
// });

/** 修正 iOS 鍵盤彈出時，鍵盤會擋住 input 問題
 * 
 * 當鍵盤出現後，嘗試將目前 activeElement 滾動至視窗中央。
 * 並在 800ms 後檢查 input 是否有在畫面中，若沒有則直接滾動至底
 */
const keyboardHeight = ref(0);
if (
  $q.platform.is.nativeMobile &&
  $q.platform.is.ios
) {
  const el = useActiveElement();
  Keyboard.addListener('keyboardWillShow', async (data) => {
    keyboardHeight.value = data.keyboardHeight;
  });
  Keyboard.addListener('keyboardWillHide', async () => {
    keyboardHeight.value = 0;
  });

  Keyboard.addListener('keyboardDidShow', async () => {
    const value = el.value;
    if (!(value instanceof HTMLInputElement || value instanceof HTMLTextAreaElement)) {
      return;
    }

    await promiseTimeout(300);

    value.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  });
}
watchEffect(() => {
  const dialogOffset = `${keyboardHeight.value}px`
  document.documentElement.style.setProperty('--dialog-offset', dialogOffset);
});
const layoutStyle = computed(() => ({
  paddingBottom: `${keyboardHeight.value}px`,
}));

/** 儲存新頁面元件是否安裝狀態，用來解決 q-page-container padding 變化導致內容跳動問題。
 * 
 * 解除前將 duration 設為 10s，舊內容不會往上跳動，安裝後將 duration 設為 0s，新內容不會往下跳。
 */
const isViewBeforeUnmount = ref(false);

/** Apple 需要發出 AppTrackingTransparency 請求 */
if ($q.platform.is.nativeMobile && $q.platform.is.ios) {
  AppTrackingTransparency.requestPermission().then((result) => {
    console.log('🚀 ~ AppTrackingTransparency result:', result);
  }).catch((error) => {
    console.log('🚀 ~ AppTrackingTransparency requestPermission error:', error);
  });
}


</script>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap')

html, body, #app
  width: 100%
  height: 100%
  padding: 0
  margin: 0
  font-family: 'Noto Sans TC', sans-serif
  color: #636363

#app
  display: flex
  flex-direction: column

.q-page
  transition-duration: 0.3s

.q-dialog__inner
  & > div:not(.q-select__dialog)
    padding-bottom: calc(var(--dialog-offset) - constant(safe-area-inset-bottom))
    padding-bottom: calc(var(--dialog-offset) - env(safe-area-inset-bottom))
</style>

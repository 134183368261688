<template>
  <q-dialog
    v-model="visible"
    persistent
  >
    <div class="border rounded bg-white w-full">
      <div class="w-full text-center px-5 py-3 bg-[#F5F3EC]">
        APP 版本更新 {{ appInfo?.version }}
      </div>

      <div class=" flex-col items-center p-6 gap-4">
        <div class=" text-center text-lg">
          目前版本 {{ versionState }}
        </div>

        <div class=" text-center">
          正在下載更新檔案，請稍後...
        </div>

        <!-- 載入動畫 -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 300 150"
          class="w-1/4 my-4"
        >
          <path
            fill="none"
            stroke="#999A86"
            stroke-width="15"
            stroke-linecap="round"
            stroke-dasharray="300 385"
            stroke-dashoffset="0"
            d="M275 75c0 31-27 50-50 50-58 0-92-100-150-100-28 0-50 22-50 50s23 50 50 50c58 0 92-100 150-100 24 0 50 19 50 50Z"
          >
            <animate
              attributeName="stroke-dashoffset"
              calcMode="spline"
              dur="2.3"
              values="685;-685"
              keySplines="0 0 1 1"
              repeatCount="indefinite"
            />
          </path>
        </svg>
      </div>
    </div>
  </q-dialog>
</template>

<script setup lang="ts">
/** 自動更新版本，會顯示更新對話框 */

import { getDomain } from '../common/utils';
import { ref } from 'vue';
import to from 'await-to-js';
import { instance } from '../common/api';
import { z } from 'zod';
import { version as _version } from '../../package.json';
import { LiveUpdate } from '@capawesome/capacitor-live-update';
import { App } from '@capacitor/app';
import { hasAtLeast, pipe } from 'remeda';
import { debounce } from 'lodash-es';

import { promiseTimeout, useAsyncState, useToggle } from '@vueuse/core';
import { useQuasar } from 'quasar';

const infoSchema = z.object({
  version: z.string(),
});

const [visible, toggleVisible] = useToggle(true);
const $q = useQuasar();

const url = getDomain();

const versionState = ref('');

const {
  state: appInfo,
  execute: checkUpdate,
} = useAsyncState(async () => {
  if (!$q.platform.is.nativeMobile) {
    return;
  }

  $q.loading.show();
  const info = await instance.get(`${url}/app.json?${Date.now()}`);
  $q.loading.hide();

  const result = infoSchema.safeParse(info.data);
  if (result.success) {
    return result.data;
  }
}, undefined, {
  async onSuccess(data) {
    if (!data) {
      toggleVisible(false);
      return;
    }

    const version = await pipe(null,
      async () => {
        const bundle = await LiveUpdate.getBundle();
        return bundle?.bundleId ?? _version;
      },
    );
    versionState.value = version;

    // 比較版本號(x.x.x)
    const hasNewVersion = pipe(null,
      () => {
        const currentVersion = version.split('.').map(Number);
        const newVersion = data.version.split('.').map(Number);

        if (!hasAtLeast(currentVersion, 3) || !hasAtLeast(newVersion, 3)) {
          return false;
        }

        return currentVersion[0] < newVersion[0] ||
          currentVersion[1] < newVersion[1] ||
          currentVersion[2] < newVersion[2]
      },
    )

    if (!hasNewVersion) {
      toggleVisible(false);
      // 準備完成，防止退回 default bundle
      LiveUpdate.ready();
      return;
    }

    toggleVisible(true);


    const [downloadError] = await to(
      LiveUpdate.downloadBundle({
        url: `${url}/app.zip`,
        bundleId: data.version,
      })
    )
    if (downloadError) {
      console.error('🚀 ~ downloadError:', downloadError);
    }

    const { bundleIds } = await LiveUpdate.getBundles();
    console.log('🚀 ~ bundleIds:', JSON.stringify(bundleIds));

    if (!bundleIds.includes(data.version)) {
      console.log('新版本為: ', data.version);
      console.log('沒有可用的 bundle: ', bundleIds);
      return;
    }

    await LiveUpdate.setBundle({ bundleId: data.version });

    $q.notify({
      type: 'positive',
      message: `更新完成，3 秒後自動重啟`,
      position: 'center',
    });

    await promiseTimeout(3000);
    await LiveUpdate.reload();
  },
})

const debounceCheckUpdate = debounce(checkUpdate, 10000, {
  leading: true,
  trailing: false,
});

// 改為只有在啟動 App 時檢查更新
// App.addListener('resume', () => {
//   debounceCheckUpdate();
// });
</script>

<style scoped lang="sass">
</style>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clip-path="url(#clip0_2524_37085)">
      <path
        d="M18.5895 0H5.4105C2.42236 0 0 2.42236 0 5.4105V18.5895C0 21.5776 2.42236 24 5.4105 24H18.5895C21.5776 24 24 21.5776 24 18.5895V5.4105C24 2.42236 21.5776 0 18.5895 0Z"
        :fill="props.bgColor"
      />
      <path
        d="M19.9996 10.8685C19.9996 7.288 16.4101 4.375 11.9978 4.375C7.58559 4.375 3.99609 7.288 3.99609 10.8685C3.99609 14.0785 6.84609 16.7672 10.6876 17.275C10.9486 17.3312 11.3033 17.4467 11.3926 17.6695C11.4736 17.872 11.4458 18.1892 11.4188 18.3932C11.4188 18.3932 11.3251 18.958 11.3048 19.078C11.2696 19.2805 11.1436 19.8693 11.9978 19.51C12.8521 19.1508 16.6058 16.7965 18.2843 14.8637C19.4438 13.5925 19.9996 12.2995 19.9996 10.8685Z"
        :fill="props.logoColor"
      />
      <path
        d="M17.337 12.9358H15.087C15.0472 12.9358 15.0091 12.92 14.9809 12.8918C14.9528 12.8637 14.937 12.8256 14.937 12.7858V9.29453C14.937 9.25475 14.9528 9.2166 14.9809 9.18847C15.0091 9.16033 15.0472 9.14453 15.087 9.14453H17.337C17.3768 9.14453 17.4149 9.16033 17.4431 9.18847C17.4712 9.2166 17.487 9.25475 17.487 9.29453V9.86228C17.487 9.90206 17.4712 9.94022 17.4431 9.96835C17.4149 9.99648 17.3768 10.0123 17.337 10.0123H15.8093V10.601H17.337C17.3768 10.601 17.4149 10.6168 17.4431 10.645C17.4712 10.6731 17.487 10.7112 17.487 10.751V11.324C17.487 11.3638 17.4712 11.402 17.4431 11.4301C17.4149 11.4582 17.3768 11.474 17.337 11.474H15.8093V12.0635H17.337C17.3768 12.0635 17.4149 12.0793 17.4431 12.1075C17.4712 12.1356 17.487 12.1737 17.487 12.2135V12.7805C17.4877 12.8007 17.4844 12.8207 17.4771 12.8395C17.4699 12.8584 17.459 12.8755 17.445 12.89C17.431 12.9045 17.4142 12.916 17.3957 12.9239C17.3771 12.9317 17.3572 12.9358 17.337 12.9358Z"
        :fill="props.bgColor"
      />
      <path
        d="M9.02177 12.9365C9.06156 12.9365 9.09971 12.9207 9.12784 12.8926C9.15597 12.8645 9.17177 12.8263 9.17177 12.7865V12.2195C9.17177 12.1797 9.15597 12.1416 9.12784 12.1135C9.09971 12.0853 9.06156 12.0695 9.02177 12.0695H7.49402V9.29453C7.49402 9.25475 7.47822 9.2166 7.45009 9.18847C7.42196 9.16033 7.38381 9.14453 7.34402 9.14453H6.77402C6.73424 9.14453 6.69609 9.16033 6.66796 9.18847C6.63983 9.2166 6.62402 9.25475 6.62402 9.29453V12.7843C6.62402 12.8241 6.63983 12.8622 6.66796 12.8903C6.69609 12.9185 6.73424 12.9343 6.77402 12.9343H9.02402L9.02177 12.9365Z"
        :fill="props.bgColor"
      />
      <path
        d="M10.3748 9.13867H9.80779C9.72329 9.13867 9.65479 9.20717 9.65479 9.29167V12.7837C9.65479 12.8682 9.72329 12.9367 9.80779 12.9367H10.3748C10.4593 12.9367 10.5278 12.8682 10.5278 12.7837V9.29167C10.5278 9.20717 10.4593 9.13867 10.3748 9.13867Z"
        :fill="props.bgColor"
      />
      <path
        d="M14.2378 9.13867H13.6708C13.631 9.13867 13.5928 9.15448 13.5647 9.18261C13.5366 9.21074 13.5208 9.24889 13.5208 9.28867V11.3632L11.9233 9.20542C11.9196 9.19971 11.9153 9.19443 11.9105 9.18967L11.9015 9.18067L11.8933 9.17392H11.8888L11.8805 9.16792H11.876L11.8678 9.16342H11.8625H11.8543H11.849H11.84H11.834H11.825H11.819H11.8108H11.2325C11.1927 9.16342 11.1546 9.17923 11.1265 9.20736C11.0983 9.23549 11.0825 9.27364 11.0825 9.31342V12.8054C11.0825 12.8452 11.0983 12.8834 11.1265 12.9115C11.1546 12.9396 11.1927 12.9554 11.2325 12.9554H11.8003C11.8401 12.9554 11.8782 12.9396 11.9063 12.9115C11.9345 12.8834 11.9503 12.8452 11.9503 12.8054V10.7107L13.55 12.8707C13.5605 12.886 13.5737 12.8992 13.589 12.9097L13.598 12.9157H13.6025L13.61 12.9194H13.6175H13.6228H13.6333C13.6465 12.9227 13.6601 12.9245 13.6738 12.9247H14.2378C14.2776 12.9247 14.3157 12.9089 14.3438 12.8807C14.372 12.8526 14.3878 12.8145 14.3878 12.7747V9.29167C14.3882 9.27172 14.3846 9.25189 14.3772 9.23335C14.3699 9.2148 14.3589 9.19791 14.3449 9.18366C14.3309 9.16941 14.3143 9.1581 14.2959 9.15037C14.2775 9.14264 14.2577 9.13867 14.2378 9.13867Z"
        :fill="props.bgColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2524_37085">
        <rect
          width="24"
          height="24"
          :fill="props.bgColor"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script setup lang="ts">
interface Props {
  bgColor?: string;
  logoColor?: string;
}
const props = withDefaults(defineProps<Props>(), {
  bgColor: '#00c300',
  logoColor: '#FFF',
});
</script>

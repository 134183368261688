<template>
  <div class=" flex flex-col flex-nowrap gap-4">
    <div class=" p-5 pb-0 flex flex-col flex-nowrap gap-4 relative flex-1">
      <div class=" text-base font-bold text-center text-[#636363]">
        已收到您的訂購與期待<br>{{ fleaMarketCreator?.name }} 感謝您完成訂單 😃
      </div>

      <!-- 訂單資訊 -->
      <div class=" text-base font-medium text-center text-[#636363]">
        訂單資訊
      </div>

      <div
        v-if="paymentDeadlineDateTime"
        class="border border-[#D9D9D9] rounded divide-y"
      >
        <!-- 付款截止時間 -->
        <div class="py-3 px-5 flex flex-nowrap justify-between items-center">
          <div class=" text-xs font-medium">
            付款截止時間
          </div>
          <div class=" text-xs font-medium text-right">
            {{ paymentDeadlineDateTime }} 前
            <div class="text-red text-sm">
              逾期付款訂單將自動取消
            </div>
          </div>
        </div>

        <!-- 付款資訊 -->
        <div class="py-3 px-5 flex flex-nowrap justify-between items-center border-t">
          <div class=" text-xs font-medium">
            付款資訊

            <div
              class=" flex-center text-xs text-[#198EE2] mt-1"
              @click="copyRemittanceAccount()"
            >
              <q-icon
                name="content_copy"
                size="1rem"
              />
              複製匯款帳號
            </div>
          </div>
          <div class=" text-xs font-medium text-right">
            {{ remittanceInfo?.bank }}<br>
            {{ remittanceInfo?.branch }}<br>
            {{ remittanceInfo?.account }}
          </div>
        </div>
      </div>

      <!-- 填寫轉帳後五碼 -->
      <template v-if="canUseAccountLast5Digits">
        <q-btn
          unelevated
          class=" text-white bg-[#F45252] btn-shadow min-h-[auto] p-4 "
          :label="accountLast5Digits ? `編輯轉帳後五碼` : '填寫轉帳後五碼'"
          @click="openAccountForm"
        />
        <div
          v-if="accountLast5Digits"
          class="text-sm text-[#198EE2] text-center font-medium"
        >
          已填寫後五碼：{{ accountLast5Digits }}
        </div>

        <div class="text-sm text-[#636363] text-center font-medium">
          如果需要稍後填寫，可以在訂單中找到此功能
        </div>
      </template>

      <div class="border border-[#D9D9D9] rounded">
        <div
          v-for="info, i in orderInfoList"
          :key="info.label"
          class="py-3 px-5 flex flex-nowrap justify-between items-center "
          :class="{ 'border-t': i !== 0 }"
        >
          <div class=" text-xs font-medium">
            {{ info.label }}
          </div>
          <div class=" text-xs font-medium text-right">
            {{ info.value }}
          </div>
        </div>
      </div>

      <!-- 賣家聯絡資料 -->
      <template v-if="contactInfo">
        <div class=" text-base font-medium text-center text-[#636363]">
          賣家聯絡資料
        </div>

        <div class="border border-[#D9D9D9] rounded p-4">
          <div class=" text-sm font-medium">
            {{ contactInfo.name }}
          </div>
          <div class=" text-xs mt-1 text-[#999898]">
            {{ contactInfo.phone }}
          </div>
        </div>

        <div
          v-if="contactInfo.line"
          class="p-3 bg-white border rounded flex items-center"
          @click="openLine(contactInfo.line)"
        >
          <icon-line-logo class="size-[1.5rem] mr-2 text-art-piece" />
          <q-chip
            :label="contactInfo?.line"
            class="bg-[#F5F3EC]"
          />
        </div>
      </template>

      <!-- 查看訂單詳情 -->
      <q-btn
        unelevated
        class="text-[#198EE2] btn-shadow min-h-[auto] p-4"
        label="查看訂單詳情"
        @click="toOrderDetail"
      />
    </div>

    <div class=" p-5 pt-0 bg-white">
      <q-btn
        unelevated
        class=" text-base font-medium btn-shadow w-full"
        label="回二手拍賣專區"
        color="flea-market"
        @click="toFleaMarket"
      />
    </div>

    <q-inner-loading :showing="isLoading" />
  </div>
</template>

<script setup lang="ts">
import { toPriceFormat } from '@jgo-idea/common';
import {
  FLEA_MARKET_SALE_PAYMENT_METHOD_MAP_BY_VALUE,
  FLEA_MARKET_SALE_DELIVERY_METHOD_MAP_BY_VALUE,
  FleaMarketOrder
} from '@jgo-idea/types';
import dayjs from 'dayjs';
import { computed, } from 'vue';
import { ROUTE_NAME } from '../../router/router';
import { openUsingDialog } from '../../common/utils-quasar'
import { instance } from '../../common/api';
import to from 'await-to-js';
import { toDateTime } from '../../common/utils';
import { pipe } from 'remeda';

import IconLineLogo from '../icon-line-logo.vue';
import RemittanceAccountLastFiveDigitsForm from '../remittance-account-last-five-digits-form.vue';

import { useFleaMarketOrderApi } from '@jgo-idea/api';
import { useRouter } from 'vue-router';
import { copyToClipboard, useQuasar } from 'quasar';
import { useAsyncState } from '@vueuse/core';
import { useNotificationStore } from '../../stores/notification.store';

interface Props {
  order?: FleaMarketOrder;
}
const props = withDefaults(defineProps<Props>(), {
  order: undefined,
});

const emit = defineEmits<{
  (e: 'close'): void;
}>();

const $q = useQuasar();
const router = useRouter();
const fleaMarketOrderApi = useFleaMarketOrderApi(instance);
const notificationStore = useNotificationStore();

const order = computed(() => newOrder.value || props.order);
const {
  state: newOrder,
  isLoading,
  execute: refreshOrder,
} = useAsyncState(async () => {
  if (!props.order) return;

  return fleaMarketOrderApi.findOne(props.order._id);
}, undefined);

const fleaMarket = computed(() => order.value?.commodities[0]?.sale);
const fleaMarketCreator = computed(() => order.value?.creator);

const paymentDeadlineDateTime = computed(() => {
  if (!order.value) return;

  return toDateTime(order.value.paymentDeadlineAt);
});

const remittanceInfo = computed(() => fleaMarket.value?.remittanceInfo);
function copyRemittanceAccount() {
  if (!remittanceInfo.value?.account) {
    $q.notify({
      type: 'negative',
      message: `未提供匯款帳號`
    });
    return;
  }

  copyToClipboard(remittanceInfo.value.account).then(() => {
    $q.notify({
      type: 'positive',
      message: `已複製匯款帳號`
    });
  });
}

const orderInfoList = computed(() => {
  const currentOrder = order.value;
  if (!currentOrder) return;

  return [
    {
      label: '訂單編號',
      value: currentOrder.number,
    },
    {
      label: '訂單成立時間',
      value: dayjs(currentOrder.timestamp.createdAt * 1000).format('YYYY/MM/DD HH:mm'),
    },
    {
      label: '運送方式',
      value: FLEA_MARKET_SALE_DELIVERY_METHOD_MAP_BY_VALUE[
        currentOrder.deliverySetting.method
      ].label,
    },
    {
      label: '付款方式',
      value: FLEA_MARKET_SALE_PAYMENT_METHOD_MAP_BY_VALUE[
        currentOrder.paymentSetting.method
      ].label,
    },
    {
      label: '總金額',
      value: `NT$${toPriceFormat(currentOrder.totalPrice)}`,
    },
  ]
});

const contactInfo = computed(() => {
  const currentOrder = order.value;
  if (!currentOrder) return;

  return currentOrder.commodities.find((item) => item.sale.sellerContactInfo)?.sale.sellerContactInfo;
});

const canUseAccountLast5Digits = computed(() => {
  /** 貨到付款不可使用轉帳 */
  return !(order.value?.deliverySetting.method === 'my-ship-711-cash-on-delivery');
});

const accountLast5Digits = computed(() => {
  if (order.value?.paymentResult?.type === 'remittance') {
    return order.value?.paymentResult.accountLast5Digits;
  }

  return undefined;
});

function openAccountForm() {
  const value = pipe(order.value?.paymentResult,
    (data) => {
      if (data?.type === 'remittance') {
        return data.accountLast5Digits;
      }

      return '';
    },
  );

  const dialog = openUsingDialog(
    RemittanceAccountLastFiveDigitsForm,
    {
      modelValue: value,
      inputColor: 'flea-market',
      submitBtnColor: 'flea-market',
      onCancel() {
        dialog.hide();
      },
      async onSubmit(data) {
        dialog.hide();
        if (!props.order) return;

        const [error] = await to(fleaMarketOrderApi.update(
          props.order._id,
          {
            paymentResult: {
              type: 'remittance',
              accountLast5Digits: data,
            },
            updateDescription: `更新匯款帳號後五碼為 ${data}`
          }
        ));

        if (error) {
          $q.notify({
            type: 'negative',
            message: `更新資料失敗：${error.message}`
          });
          return;
        }

        $q.notify({
          type: 'positive',
          message: `已儲存 ${data} 後五碼資料`
        });
        refreshOrder();
      }
    },
    undefined,
    {
      // position: 'bottom',
      maximized: true,
      transitionShow: 'slide-up',
      transitionHide: 'slide-down',
    },
  );
}
function openLine(link: string) {
  window.open(
    `https://line.me/R/ti/p/~${link}`,
    '_blank'
  );
}

function toOrderDetail() {
  router.push({
    name: ROUTE_NAME.ACCOUNT_FLEA_MARKET_ORDER_DETAIL,
    params: {
      id: order.value?._id,
    },
  });
}

function toFleaMarket() {
  router.push({
    name: ROUTE_NAME.FLEA_MARKET
  });
}

function init() {
  if (order.value?._id) {
    notificationStore.subscribe({
      id: order.value?._id
    })
  }
}
init();
</script>

<style scoped lang="sass">
</style>
